var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-list-container" },
    [
      _c("Title", { attrs: { name: "产品列表" } }),
      _c(
        "div",
        { staticClass: "filter-content" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              size: "mini",
              placeholder: "请输入商品编号",
            },
            model: {
              value: _vm.listQuery.goodsSn,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "goodsSn", $$v)
              },
              expression: "listQuery.goodsSn",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              size: "mini",
              placeholder: "请输入商品名称",
            },
            model: {
              value: _vm.listQuery.name,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "name", $$v)
              },
              expression: "listQuery.name",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { size: "mini", type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("查找")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": {
                  background: "#f5f5f5",
                  "font-weight": 500,
                  color: "#666",
                  padding: "12 5px",
                },
                data: _vm.list,
                "element-loading-text": "正在查询中。。。",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "商品编号", prop: "goodsSn" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "130",
                  label: "名称",
                  prop: "name",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "当前价格",
                  prop: "retailPrice",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.isLvdingPrice
                                ? isNaN(scope.row.processCharge)
                                  ? _vm.lvPrice.taxPrice
                                  : _vm.lvPrice.taxPrice +
                                    scope.row.processCharge
                                : scope.row.retailPrice
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "材质", prop: "material" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "仓库", prop: "entrepot" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "是否在售", prop: "isOnSale" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: scope.row.isOnSale ? "success" : "error",
                            },
                          },
                          [_vm._v(_vm._s(scope.row.isOnSale ? "在售" : "未售"))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "南海灵通铝价",
                  prop: "isOnSale",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: scope.row.isLvdingPrice
                                ? "success"
                                : "error",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.isLvdingPrice ? "使用" : "未使用"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "审核状态", prop: "isOnSale" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.approveStatus === 0
                          ? _c("el-tag", { staticStyle: { width: "70px" } }, [
                              _vm._v("审核中"),
                            ])
                          : _vm._e(),
                        scope.row.approveStatus === 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("审核通过"),
                            ])
                          : _vm._e(),
                        scope.row.approveStatus === 2
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("审核拒绝"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  label: "操作",
                  width: "180",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.total
        ? _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.total,
                  "page-size": _vm.listQuery.limit,
                  "current-page": _vm.listQuery.page,
                },
                on: { "current-change": _vm.changeCurrentPage },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品审核",
            visible: _vm.dialogVisible,
            "min-width": "380px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "check",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.check,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否通过：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.check.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "state", $$v)
                        },
                        expression: "check.state",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("通过")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("拒绝")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审核备注：", prop: "traceMsg" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.check.msg,
                      callback: function ($$v) {
                        _vm.$set(_vm.check, "msg", $$v)
                      },
                      expression: "check.msg",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSubmitForm },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }