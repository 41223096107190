<template>
    <div class="product-list-container">
        <Title name="产品列表"></Title>
        <!-- 查询和其他操作 -->
        <div class="filter-content">
            <el-input v-model="listQuery.goodsSn" clearable size="mini" class="filter-item" style="width: 200px;" placeholder="请输入商品编号"/>
            <el-input v-model="listQuery.name" clearable size="mini" class="filter-item" style="width: 200px;" placeholder="请输入商品名称"/>
            <el-button size="mini" class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">查找</el-button>
            <!--<el-button size="mini" class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>-->
            <!-- <el-button :loading="downloadLoading" size="mini" class="filter-item" type="warning" icon="el-icon-download" @click="handleDownload">导出</el-button> -->
        </div>

        <!-- 查询结果 -->
        <div class="table-content">
            <el-table
                v-loading="listLoading"
                :header-cell-style="{'background':'#f5f5f5','font-weight': 500,'color':'#666','padding':'12 5px'}"
                :data="list"
                style="width: 100%"
                element-loading-text="正在查询中。。。" >
                <el-table-column align="center"  label="商品编号" prop="goodsSn"/>

                <el-table-column align="center" min-width="130" label="名称" prop="name"/>

                <!-- <el-table-column align="center" property="iconUrl" label="图片">-->
                <!--    <template slot-scope="scope">-->
                <!--    <img :src="scope.row.picUrl" width="40">-->
                <!--    </template>-->
                <!--</el-table-column> -->

                <!-- <el-table-column align="center" property="iconUrl" label="分享图">
                    <template slot-scope="scope">
                    <img :src="scope.row.shareUrl" width="40">
                    </template>
                </el-table-column> -->

                <!-- <el-table-column align="center" label="详情" prop="detail">
                    <template slot-scope="scope">
                    <el-dialog :visible.sync="detailDialogVisible" title="商品详情">
                        <div v-html="goodsDetail"/>
                    </el-dialog>
                    <el-button type="primary" size="mini" @click="showDetail(scope.row.detail)">查看</el-button>
                    </template>
                </el-table-column> -->

                <!--<el-table-column align="center" label="专柜价格" prop="counterPrice"/>-->
                <el-table-column align="center" label="当前价格" prop="retailPrice" sortable>
                    <template slot-scope="scope">
                        {{scope.row.isLvdingPrice?(isNaN(scope.row.processCharge)?
                        lvPrice.taxPrice:lvPrice.taxPrice+scope.row.processCharge):
                        scope.row.retailPrice}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="材质" prop="material"/>
                <el-table-column align="center" label="仓库" prop="entrepot"/>


                <!--<el-table-column align="center" label="是否新品" prop="isNew">-->
                <!--    <template slot-scope="scope">-->
                <!--        <el-tag :type="scope.row.isNew ? 'success' : 'error' ">{{ scope.row.isNew ? '新品' : '非新品' }}</el-tag>-->
                <!--    </template>-->
                <!--</el-table-column>-->

                <!--<el-table-column align="center" label="是否热品" prop="isHot">-->
                <!--    <template slot-scope="scope">-->
                <!--        <el-tag :type="scope.row.isHot ? 'success' : 'error' ">{{ scope.row.isHot ? '热品' : '非热品' }}</el-tag>-->
                <!--    </template>-->
                <!--</el-table-column>-->

                <el-table-column align="center" label="是否在售" prop="isOnSale">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isOnSale ? 'success' : 'error' ">{{ scope.row.isOnSale ? '在售' : '未售' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="南海灵通铝价" prop="isOnSale">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isLvdingPrice ? 'success' : 'error' ">{{ scope.row.isLvdingPrice ? '使用' : '未使用' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="审核状态" prop="isOnSale">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.approveStatus === 0" style="width: 70px;">审核中</el-tag>
                        <el-tag v-if="scope.row.approveStatus === 1" type="success">审核通过</el-tag>
                        <el-tag v-if="scope.row.approveStatus === 2" type="danger">审核拒绝</el-tag>
                    </template>
                </el-table-column>

                <el-table-column align="center" fixed="right" label="操作" width="180" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                        <el-button size="small" type="text" @click="handleUpdate(scope.row.id)">编辑</el-button>
                        <el-button size="small" type="text" @click="handleDelete(scope.row.id)">删除</el-button>
                        <!-- <el-button v-if="scope.row.approveStatus === 0" size="small" type="text" @click="handleCheckclick(scope.row)">审核</el-button> -->
                        <!--<el-button size="small" type="text" @click="handleCheckclick(scope.row)">审核</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="pagination" v-if="total">
            <el-pagination
                background layout="prev, pager, next"
                :total='total'
                :page-size='listQuery.limit'
                :current-page="listQuery.page"
                @current-change="changeCurrentPage"
            >
            </el-pagination>
        </div>

        <el-dialog
                title="商品审核"
                :visible.sync="dialogVisible"
                min-width="380px">
            <el-form ref="check" :model="check" status-icon label-position="left" label-width="100px" style="width: 400px; margin-left:50px;">
                <el-form-item label="是否通过：" >
                    <el-radio-group v-model="check.state">
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="2">拒绝</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="审核备注：" prop="traceMsg">
                    <el-input v-model="check.msg"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible=false">取消</el-button>
                <el-button type="primary" @click="handleSubmitForm">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getClientGoods,delGoods,goodCheck,newestLvdingPrice} from '../../api'
import Title from '../../component/Title';
export default {
    components: {
        Title
    },
    data() {
        return {
            downloadLoading: false,
            listLoading: false,
            list: [{
               }],
            listQuery: {
                page: 1,
                limit: 10,
                goodsSn: undefined,
                name: undefined,
                sort: 'add_time',
                order: 'desc'
            },
            total: 0,
        // 审核
            dialogVisible:false,
            check:{
                id:null,
                state:1,
                msg:'',
            },
            lvPrice:{},
        }
    },
    created() {
        this.getProductList();
    },
    methods: {
        handleFilter() {
            this.listQuery.page = 1
            this.getProductList()
        },
        handleCreate() {
            this.$router.push({path: '/User/AddProduct'});
        },
        handleDownload() {

        },
        handleUpdate(row) {
            //编辑
            this.$router.push({ path: '/User/AddProduct',query: { id: row }})
        },
        handleDelete(id) {
            this.$confirm('此操作将删除该产品信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delGoods({id}).then(res=>{
                    this.getProductList();
                })
            });
        },
        getProductList() {
            getClientGoods(this.listQuery) .then(
                res => {
                    if(res.errno==0){
                        this.list=res.data.goodsList;
                        this.total=res.data.total;
                        newestLvdingPrice().then(res2=>{
                            this.lvPrice=res2.data
                        })
                    }
                }
            )
        },
        changeCurrentPage(num){
            this.listQuery.page = num;
            this.getProductList();
        },
        // 商品审核
        handleSubmitForm(){
            goodCheck(this.check).then(res=>{
                if(res.errno===0){
                    this.dialogVisible=false;
                    this.getProductList();
                }
            })
        },
        handleCheckclick(row){
            this.dialogVisible=true;
            this.check.id=row.id
        }

    }
}
</script>

<style lang="less">
.product-list-container {
    background: #fff        ;

    .filter-content {
        padding: 20px 10px;
        box-sizing: border-box;
    }
    .table-content {
        padding: 10px;
        box-sizing: border-box;
    }
	// .el-dialog {
    //     width: 60%;
    // }
    // .table-expand {
    //     font-size: 0;
    // }
    // .table-expand label {
    //     width: 100px;
    //     color: #99a9bf;
    // }
    // .table-expand .el-form-item {
    //     margin-right: 0;
    //     margin-bottom: 0;
    // }
    // .gallery {
    //     width: 80px;
    //     margin-right: 10px;
    // }
    .pagination {
        padding: 0 20px;
        box-sizing: border-box;
        text-align: right;
    }
}
</style>
